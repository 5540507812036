

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          {/* Brand Section */}
          <Grid item xs={12} md={3} sx={{ mb: 3 }} >
            <MKBox>
              <Link to={brand.route}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="2rem" mb={2} />
              </Link>
              <MKTypography variant="h6" color="dark">{brand.name}</MKTypography>
            </MKBox>
          </Grid>

          {/* Address and Social Icons Section */}
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            {/* Address */}
            {menus
              .filter((menu) => menu.name === "Contact Info")
              .map(({ items }) => (
                <MKBox key="address" mt={3}>
                  {items.map(({ icon, name }) => (
                    <MKTypography
                      key={name}
                      variant="button"
                      fontWeight="regular"
                      textTransform="capitalize"
                      display="flex"
                       color="dark"
                      alignItems="center"
                      mb={1}
                    >
                      {icon && <MKBox mr={1} color="info">{icon}</MKBox>}
                      {name}
                    </MKTypography>
                  ))}
                </MKBox>
              ))}

            {/* Social Icons */}
            <MKBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <MKTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </MKTypography>
              ))}
            </MKBox>
          </Grid>

          {/* Menus Section */}
          <Grid item xs={12} md={6} sx={{ mb: 3 }}>
            <MKBox display="flex" justifyContent="space-between">
              {menus
                .filter((menu) => menu.name !== "Contact Info")
                .map(({ name: title, items }) => (
                  <Grid key={title} item xs={6} md={4} sx={{ mb: 3 }}>
                    <MKTypography
                      display="block"
                      variant="button"
                      fontWeight="bold"
                       color="info"
                      textTransform="capitalize"
                      mb={1}
                    >
                      {title}
                    </MKTypography>
                    <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                      {items.map(({ icon, name, route, href }) => (
                        <MKBox key={name} component="li" p={0} m={0} lineHeight={1.25}>
                          {href ? (
                            <MKTypography
                              component="a"
                              href={href}
                              target="_blank"
                              rel="noreferrer"
                              variant="button"
                              fontWeight="regular"
                              color="dark"
                              textTransform="capitalize"
                              display="flex"
                              alignItems="center"
                            >
                              {icon && <MKBox mr={1}>{icon}</MKBox>}
                              {name}
                            </MKTypography>
                          ) : (
                            <MKTypography
                              component={Link}
                              to={route}
                              variant="button"
                              fontWeight="regular"
                              textTransform="capitalize"
                              display="flex"
                              color="light"
                              alignItems="center"
                            >
                              {icon && <MKBox mr={1}>{icon}</MKBox>}
                              {name}
                            </MKTypography>
                          )}
                        </MKBox>
                      ))}
                    </MKBox>
                  </Grid>
                ))}
            </MKBox>
          </Grid>

          {/* Copyright Section */}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;

