// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";
import {  Email, LocationOn, Phone, WhatsApp } from "@mui/icons-material";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Dk Constructions",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "#",
    },
    {
      icon: <TwitterIcon />,
      link: "@",
    },
    {
      icon: <WhatsApp />,
      link: "#",
    },
    {
      icon: <YouTubeIcon />,
      link: "#",
    },
  ],
  address:[
    {
      name: "Quick links",
      items: [
        { name: "Home", href: "#" },
        { name: "About us", href: "/pages/landing-pages/about-us" },
        { name: "Contact us", href: "/pages/landing-pages/contact-us" },
        { name: "Author", href: "/pages/landing-pages/author" },
      ],
    },

  ],
  menus: [
    {
      name: "Quick links",
      items: [
        { name: "Home", href: "#" },
        { name: "About us", href: "/pages/landing-pages/about-us" },
        { name: "Contact us", href: "/pages/landing-pages/contact-us" },
        { name: "Author", href: "/pages/landing-pages/author" },
      ],
    },
    {
      name: "Contact Info",
      items: [
        { icon:<LocationOn/> , name: "Near Lekha Nagar, Old Cidco, Nashik. 422009", href: "" },
        {  icon:<Email/> ,name: "dineshkumarkumawat093@gmail.com", href: "#" },
        {  icon:<Phone/> ,name: "+91 83087 40522", href: "#" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "#/contact-us" },
        { name: "knowledge center", href: "#/knowledge-center" },
        { name: "custom development", href: "#" },
        { name: "sponsorships", href: "#/sponsorships" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "#/terms" },
        { name: "privacy policy", href: "#/privacy" },
        { name: "licenses (EULA)", href: "#/license" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} by{" "}
      <MKTypography
        component="a"
        href="#"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Dipak Kumawat
      </MKTypography>
      .
    </MKTypography>
  ),
};
