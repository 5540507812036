export default [
  {
    title: "Project Site 1",
    description: "dustry. Lorem Ipsum has been the industry's standard",
    items: [
      {
        image: require("assets/image/site-1.jpeg"),
        name: "Pricing",
        count: 8,
        pro: false,
      },
      {
        image: require("assets/image/site-1.jpeg"),
        name: "room",
        count: 1,
        pro: false,
      },

      {
        image: require("assets/image/site-1.jpeg"),
        name: "room",
        count: 3,
        pro: false,
      },

      {
        image: require("assets/image/site-1.jpeg"),
        name: "room",
        count: 4,
        pro: false,
      },
    ],
  },
  {
    title: "Project Site 2",
    description: "dustry. Lorem Ipsum has been the industry's standard",
    items: [
      {
        image: require("assets/image/site-2.jpeg"),
        name: "Rooms",
        count: 4,
        // route: "/sections/navigation/navbars",
      },
      {
        image: require("assets/image/site-2.jpeg"),
        name: "Rooms",
        count: 2,
        // route: "/sections/navigation/nav-tabs",
      },
      {
        image: require("assets/image/site-2.jpeg"),
        name: "Rooms",
        count: 3,
        // route: "/sections/navigation/pagination",
      },
    ],
  },
  {
    title: "Project site 3",
    description: "dustry. Lorem Ipsum has been the industry's standard",
    items: [
      {
        image: require("assets/image/site-3.jpeg"),
        name: "Rooms",
        count: 6,
        pro: false,
      },
      {
        image: require("assets/image/site-3.jpeg"),
        name: "Rooms",
        count: 8,
        pro: false,
      },
      {
        image: require("assets/image/site-3.jpeg"),
        name: "Rooms",
        count: 3,
        // route: "/sections/input-areas/forms",
      },
      {
        image: require("assets/image/site-3.jpeg"),
        name: "Rooms",
        count: 6,
        // route: "/sections/input-areas/inputs",
      },
    ],
  },
  {
    title: "Project site 4",
    description: "dustry. Lorem Ipsum has been the industry's standard",
    items: [
      {
        image: require("assets/image/site-4.jpeg"),
        name: "Rooms",
        count: 4,
        // route: "/sections/attention-catchers/alerts",
      },
      {
        image: require("assets/image/site-4.jpeg"),
        name: "Rooms",
        count: 3,
        pro: false,
      },
      {
        image: require("assets/image/site-4.jpeg"),
        name: "Rooms",
        count: 2,
        // route: "/sections/attention-catchers/tooltips-popovers",
      },
      {
        image: require("assets/image/site-4.jpeg"),
        name: "Rooms",
        count: 5,
        // route: "/sections/attention-catchers/modals",
      },
    ],
  },
  // {
  //   title: "Elements",
  //   description: "dustry. Lorem Ipsum has been the industry's standard",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       // route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       // route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       // route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       // route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: false,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       // route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       // route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       // route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: false,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       // route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
