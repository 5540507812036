

// @mui material components
import Icon from "@mui/material/Icon";

// @mui icons
import InstagramIcon from "@mui/icons-material/Instagram";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Author from "layouts/pages/landing-pages/author";

const routes = [
  {
    name: "about us",
    icon: <Icon>info</Icon>,
    route: "/pages/landing-pages/about-us",
    component: <AboutUs />,
  },
  {
    name: "contact us",
    icon: <Icon>contact_mail</Icon>,
    route: "/pages/landing-pages/contact-us",
    component: <ContactUs />,
  },
  {
    name: "author",
    icon: <Icon>person</Icon>,
    route: "/pages/landing-pages/author",
    component: <Author />,
  },
  {
    name: "instagram",
    icon: <InstagramIcon />,
    href: "https://www.instagram.com/dineshkumarkumawat674/",
  },
];

export default routes;
