/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKSocialButton from "components/MKSocialButton";
import MKTypography from "components/MKTypography";

function Footer() {
  return (
    <MKBox component="footer" py={6}>
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            lg={4}
            textAlign={{ xs: "center", lg: "left" }}
            mr="auto"
            mb={{ xs: 3, lg: 0 }}
          >
            <MKTypography variant="h6" textTransform="uppercase" mb={{ xs: 2, lg: 3 }}>
              Dipak Kumawat
            </MKTypography>
            <Stack
              component="ul"
              direction="row"
              flexWrap="wrap"
              spacing={3}
              justifyContent={{ xs: "center", lg: "flex-start" }}
              pl={0}
              mb={3}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Home
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  opacity={0.8}
                  component={Link}
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  Services
                </MKTypography>
              </MKBox>
            </Stack>
            <MKTypography variant="button" opacity={0.8}>
              Copyright © <script>document.write(new Date().getFullYear())</script>2024 by Dipak
              Kumawat
            </MKTypography>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            my={{ xs: 5, lg: "auto" }}
            mr={{ xs: 0, lg: "auto" }}
            sx={{ textAlign: { xs: "center", lg: "right" } }}
          >
            <MKSocialButton
              component="a"
              // href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
              href="#"
              target="_blank"
              color="twitter"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-twitter" />
              &nbsp;Follow
            </MKSocialButton>
            <MKSocialButton
              component="a"
              // href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
              href="#"
              target="_blank"
              color="facebook"
              sx={{ mr: 1 }}
            >
              <i className="fab fa-facebook" />
              &nbsp;Share
            </MKSocialButton>
            <MKSocialButton
              component="a"
              href="#"
              // href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
              target="_blank"
              color="pinterest"
            >
              <i className="fab fa-pinterest" />
              &nbsp;Pin it
            </MKSocialButton>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
