export default [
  {
    image: require("assets/image/pageDate-3.jpg"),
    name: "Tiles 4",
    route: "/pages/landing-pages/about-us",
  },
  {
    image: require("assets/image/pageDate-3.jpg"),
    name: "Tiles 3",
    route: "/pages/landing-pages/contact-us",
  },
  {
    image: require("assets/image/pageDate-3.jpg"),
    name: "tiles 2",
    route: "/pages/authentication/sign-in",
  },
  {
    image: require("assets/image/pageDate-3.jpg"),
    name: "Tiles 1",
    route: "/pages/landing-pages/author",
  },
];
